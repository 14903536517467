import { YStack, ScrollView, Text } from '@bp/ui'
import React from 'react'

export default function Placeholder({ text = 'Hello! This page is WIP' }) {
  return (
    <ScrollView bc="$background">
      <YStack justifyContent="center" alignItems="center">
        <YStack
          px="$7"
          pt="$5"
          $gtSm={{
            width: '700px',
          }}
          width="100vw"
          bc="$lightest"
          elevation={1}
        >
          <Text variant="H3" mb="$16">
            {text}
          </Text>
        </YStack>
      </YStack>
    </ScrollView>
  )
}
