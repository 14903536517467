import { Home } from 'app/features/misc'
import Placeholder from 'app/features/misc/placeholder'
import Head from 'next/head'
import { useAuth } from 'app/lib/auth'

export default function Page() {
  const { user, isLoading } = useAuth()
  return (
    <>
      <Head>
        <title>Home</title>
      </Head>
      {user ? <Placeholder text={'Home feeds'} /> : <Home />}
    </>
  )
}
